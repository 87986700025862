<template>
  <div class="ck-character-count-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: "CkCharacterCountGroup"
};
</script>

<style lang="scss">
.ck-character-count-group {
  position: relative;
}
</style>
